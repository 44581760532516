"use client"

import type { FormEventHandler, FunctionComponent } from "react"

import { useEffect, useRef } from "react"

import { Button } from "@jobteaser/spark/components/Button"
import { Search } from "@jobteaser/spark/components/Icons/Search"

import { useLocale } from "@/modules/locales/useLocale"
import { useTracking } from "@/modules/tracking/useTracking"

import styles from "./SearchBar.module.css"

type SearchBarProps = {
  searchPlaceholder: string
  buttonLabel: string
}

export const SearchBar: FunctionComponent<SearchBarProps> = ({ searchPlaceholder, buttonLabel }) => {
  const locale = useLocale()
  const tracking = useTracking()
  const ref = useRef<HTMLFormElement>(null)

  useEffect(() => {
    const input = document.getElementById("homePageSearchInput") as HTMLInputElement
    if (input) {
      input.addEventListener("focus", event => {
        const target = event.target as HTMLInputElement
        target.placeholder = ""
      })

      input.addEventListener("blur", event => {
        const target = event.target as HTMLInputElement

        if (!target.value) {
          target.placeholder = searchPlaceholder
        }
      })
    }
  }, [searchPlaceholder])

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()

    if (!ref.current) {
      return
    }

    tracking?.setRefererParams(
      {
        from_feature: "homepage",
        is_from_b2c_homepage: true,
        module: "fo_homepage",
        role: "search_bar_click",
      },
      { keyName: `/${locale}/job-offers` }
    )

    ref.current.submit()
  }

  return (
    <form action={`/${locale}/job-offers`} method="GET" onSubmit={handleSubmit} ref={ref}>
      <div className={styles.searchContainer}>
        <div className={styles.inputContainer}>
          <Search className={styles.searchIcon} />
          <input
            id="homePageSearchInput"
            className={styles.searchInput}
            type="text"
            placeholder={searchPlaceholder}
            name="q"
            required
          />
        </div>
        <Button type="submit" variant="highlight" className={styles.searchButton}>
          {buttonLabel}
        </Button>
      </div>
    </form>
  )
}
