"use client"

import type { TrackingPageViewProps } from "@/modules/tracking/types"
import type { FunctionComponent, PropsWithChildren } from "react"

import { usePathname } from "next/navigation"
import { useEffect } from "react"

import { useTrackingPageView } from "@/modules/tracking/useTrackingPageView"

type AppTrackingPageViewPropsUpdaterProps = PropsWithChildren<{
  pageViewProps?: TrackingPageViewProps
}>

export const AppTrackingPageViewPropsUpdater: FunctionComponent<AppTrackingPageViewPropsUpdaterProps> = ({
  children,
  pageViewProps = {},
}) => {
  const pathname = usePathname()
  const { setPageViewProps } = useTrackingPageView()

  useEffect(() => {
    setPageViewProps({ ...pageViewProps, __pathname: pathname })

    return () => {
      setPageViewProps(null)
    }
  }, [pageViewProps, pathname, setPageViewProps])

  return children
}
