"use client"

import type { FunctionComponent } from "react"

import dynamic from "next/dynamic"
import Image from "next/image"
import { useState } from "react"

import youtubePlayIcon from "@/modules/corporate/images/youtube_play_icon.png"

import styles from "./VideoPlayer.module.css"

type VideoPlayerProps = {
  videoId: string
  url: string
}

const DynamicVideoPlayer = dynamic(
  () => import("@jobteaser/ui-kit/components/VideoPlayer/VideoPlayer").then(mod => mod.VideoPlayer),
  { ssr: false }
)

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({ videoId, url }) => {
  const [showVideo, setShowVideo] = useState(false)
  const thumbnailSrc = `https://img.youtube.com/vi_webp/${videoId}/maxresdefault.webp`

  const handleOnClick = (): void => {
    if (!showVideo) {
      setShowVideo(true)
    }
  }

  return (
    <div className={styles.wrapper} onClick={handleOnClick} onKeyDown={handleOnClick} role="button" tabIndex={0}>
      {thumbnailSrc && !showVideo && (
        <>
          <Image alt="Play the video" className={styles.icon} height={48} priority src={youtubePlayIcon} width={68} />

          <figure className={styles.thumbnailWrapper}>
            <Image
              alt="Guiding students. Shaping professionals."
              className={styles.thumbnail}
              priority
              sizes="100vw"
              src={thumbnailSrc}
              /* hq random 16 / 9 size, resize is done after on css side */
              height="540"
              width="960"
            />
          </figure>
        </>
      )}

      {showVideo && (
        <div className={styles.videoWrapper}>
          <DynamicVideoPlayer playing url={url} className={styles.video} />
        </div>
      )}
    </div>
  )
}
