"use client"

import type { I18nLocale } from "@/modules/i18n/types"

import { useParams } from "next/navigation"

import { DEFAULT_LOCALE } from "./constants"

export function useLocale(): I18nLocale {
  const params = useParams<{ pageLocale: I18nLocale }>()

  if (!params || !params.pageLocale) {
    return DEFAULT_LOCALE
  }

  const { pageLocale: locale } = params

  return locale as I18nLocale
}
